.add-to-client-modal {
	display: flex;
	padding: 24px;
	flex-direction: column;
	gap: 16px;
	width: 448px;
	background-color: #eef2f3;
	align-items: center;
	justify-content: center;
	position: relative;

	.add-to-client-header {
		display: flex;
		flex-direction: column;
		gap: 24px;
		background-color: #eef2f3;
		width: 100%;
	}
	.add-to-client-body {
		display: flex;
		width: 100%;
		flex-direction: column;
		min-height: 45vh;
		max-height: 45vh;
		overflow: auto;
		background-color: #eef2f3;
		border-radius: 4px 4px 0px 0px;
	}

	.add-to-client-footer {
		position: absolute;
		bottom: 0;
		height: 64px;
		background-color: #f7f9fa;
		border-radius: 0px 0px 4px 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		width: 100%;
	}
}
