@import '../../style-assets/colors';

.my-task-modal {
	width: 448px;
    height: 95vh;
	max-height: 800px;

	display: flex;
	flex-direction: column;
	justify-content: center;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
    overflow: hidden;
}

.my-task-body {
	display: flex;
	flex-direction: column;
	// gap: 24px;

	background-color: $formbg;
	padding: 24px;
	padding-bottom: 0px;
	// border: 2px solid red;

    box-sizing: border-box;
    overflow: hidden;
	.MuiSelect-select:focus {
		background-color: transparent
	}
}

.my-task-body-child{
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	overflow: hidden;
	box-sizing: border-box;
}

.my-task-inner-body {
	display: flex;
	flex-direction: column;
	gap: 16px;
    box-sizing: border-box;
    overflow: hidden;

	.my-task-types {
		display: flex;
		width: 100%;
		box-sizing: border-box;
	}

	.my-task-type {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
        padding: 10px;
		border-radius: 3px;
        cursor: pointer;
	}

    .active-form-tab{
        background-color: $formActivebg;
        color: $primaryColor;
    }

    .MuiFormControl-root {
        background-color:white !important;
    }
}

.my-task-form-actions{
    background-color: white;
    padding: 16px 24px;

    display: flex;
    justify-content: space-between;
	align-items: center;
    border-radius: 0px 0px 4px 4px ;
}

// .task-inputs-container{
// 	display: flex;
// 	flex-direction: column;
// 	gap: 14px;
// }