@import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:300italic,700italic,400,300);

// Fonts

@font-face {
	font-family: 'Calibre';
	src: url('../../fonts/CalibreWeb-Regular.woff2') format('woff2'),
		url('../../fonts/CalibreWeb-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calibre-Light';
	src: url('../../fonts/CalibreWeb-Light.woff2') format('woff2'),
		url('../../fonts/CalibreWeb-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Calibre-Bold';
	src: url('../../fonts/CalibreWeb-Bold.woff2') format('woff2'),
		url('../../fonts/CalibreWeb-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Calibre-Semibold';
	src: url('../../fonts/CalibreWeb-Semibold.woff2') format('woff2'),
		url('../../fonts/CalibreWeb-Semibold.woff') format('woff');
	// font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Tiempos';
	src: url('../../fonts/tiempos-fine-web-regular.woff2') format('woff2'),
		url('../../fonts/tiempos-fine-web-regular.woff') format('woff'),
		url('../../fonts/tiempos-fine-web-regular.eot') format('eot');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Tiempos-Semibold';
	src: url('../../fonts/tiempos-fine-web-semibold.woff2') format('woff2'),
		url('../../fonts/tiempos-fine-web-semibold.woff') format('woff'),
		url('../../fonts/tiempos-fine-web-semibold.eot') format('eot');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Centra';
	src: url('../../fonts/CentraNo2.woff2') format('woff2'),
		url('../../fonts/CentraNo2.woff') format('woff'),
		url('../../fonts/CentraNo2.eot') format('eot');
}

@font-face {
	font-family: 'Centra-No2';
	src: url('../../fonts/CentraNo2/CentraNo2-Book.ttf');
}

@font-face {
	font-family: 'Centra-bold';
	src: url('../../fonts/CentraNo2/CentraNo2-Medium.woff2') format('woff2'),
		url('../../fonts/CentraNo2/CentraNo2-Medium.woff') format('woff');
}

@font-face {
	font-family: 'CentraLight';
	src: url('../../fonts/CentraNo2/CentraNo2-Light.woff2') format('woff2'),
		url('../../fonts/CentraNo2/CentraNo2-Light.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/poppins/Poppins-Regular.woff2') format('woff2');
}

@mixin typography(
	$fontFamily,
	$fontWeight,
	$fontSize,
	$lineHight,
	$letterSpacing,
	$color
) {
	font-family: $fontFamily;
	font-style: normal;
	font-weight: $fontWeight;
	font-size: $fontSize;
	line-height: $lineHight;
	letter-spacing: $letterSpacing;
	@if $color == null {
		color: #555555;
	} @else {
		color: $color;
	}
}
