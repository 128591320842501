#profile{
    margin-top:23px;
    padding:0px 64px 0px 32px;
    .heading{
        display: flex;
        justify-content: space-between;
        padding: 0.6rem;
        background: $backgroundGrey;
        border-radius: 3px;
        .text{
            margin-top: 0.6rem;
        }
    }
    .body{
        padding-left: 15px;
        padding-right: 15px;
        .fileInput{
            color:$lightText;
            .fileInputField::-webkit-file-upload-button {
                display: none;
              }
              .fileInputField::before {
                content: 'Pick File';
                display: inline-block;
                background: $secondaryButtonGrey;
                border: none;
                border-radius: 3px;
                padding: 0.3rem 1rem;
                color: $black;
                outline: none;
                // white-space: nowrap;
                // -webkit-user-select: none;
                // cursor: pointer;
                // text-shadow: 1px 1px #fff;
                font-weight: 600;
                font-size: 14px;
                margin-right: 0.2rem;
              }
            //   .fileInputField:hover::before {
            //     border-color: black;
            //   }
            //   .fileInputField:active::before {
            //     background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
            //   }
        }
        .lightText{
            color: $lightText;
            font-size: 12px;
            line-height: 14px;
        }
        .avatar{
            width:5.6rem;
            height:5.6rem;
            background: $secondaryButtonGrey;
            border-radius:50% ;
            font-size: 40px;
            font-weight: 600;
            overflow: hidden;
        }
        .avatarText{
            width:5.6rem;
            height:5.6rem;
            background: $secondaryButtonGrey;
            border-radius:50% ;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            font-weight: 600;
        }
    }
}