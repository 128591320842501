@import "../../style-assets/colors";

.SnackBarAlert{
    background-color: white;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
    color: #333333;
    padding: 8px 18px;
    border-radius: 4px;
    width: 338px;
    min-height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
