@import '../../style-assets/colors';
@import '../../style-assets/misc';
@import '../../style-assets/spacing';

.PickList {
	font-family: 'CentraLight';
	font-weight: light;
	font-size: 14px !important;
	line-height: 18px;
	padding: 6px;
	border: none;
	// border-bottom: 1px solid $darkGreen;
	outline: none;

	background-color: white;
	appearance: none;
	background-repeat: no-repeat;
	background-position: right 4px top 50%;
	background-size: 13px 13px;
	background-image: url('data:image/svg+xml,%3Csvg width="13" height="13" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath  stroke="orange" fill="orange" fillRule="nonzero" d="M12.166 6.5l-.998-1-3.96 3.953V.833H5.791v8.62l-3.952-3.96L.833 6.501 6.5 12.167z"/%3E%3C/svg%3E');
	border-radius: 4px;
	min-height: auto;

	&:focus {
		border-bottom: 1px solid $primaryColor !important;
	}
	.valid-options {
		color: $darkText !important;
		background-color: white;
	}

	.valid-options.disabled,
	.not-active {
		color: $lightdarkText !important;
	}
}

.not-selected {
	color: #696f6c;
}

.PickList > option[value='null'] {
	color: $placeholderText;
}

.PickList-Edittable {
	padding: 0.15rem;
	border: none;
	outline: none;
	height: 22px;
	background-color: transparent;
	appearance: none;
	background-repeat: no-repeat;
	background-position: right 4px top 50%;
	background-size: 13px 13px;
	background-image: url('data:image/svg+xml,%3Csvg width="13" height="13" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath  stroke="orange" fill="orange" fillRule="nonzero" d="M12.166 6.5l-.998-1-3.96 3.953V.833H5.791v8.62l-3.952-3.96L.833 6.501 6.5 12.167z"/%3E%3C/svg%3E');

	background-color: white;
}
