@import '../../style-assets/colors';

.NewsFeedLauncher-Wrapper {
	padding: 8px 12px;
	width: 100%;
}

.NewsFeedLauncher {
	display: flex;
	align-items: center;
	justify-content: space-between;

	cursor: pointer;
	background: #364e55;

	border-radius: 4px;
	padding: 8px;

	.container {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	.badge {
		background: $primaryColor;
		border-radius: 50%;
	}
}
