@import '../../style-assets/colors';
@import '../../style-assets/spacing';

.Workflow-Builder {
	height: 100vh;
	width: 100vw;

	min-width: 1200px;

	margin: 0;

	display: flex;
	flex-direction: row;

	// border: 2px solid red;

	div {
		// border: 1px solid green;
		box-sizing: border-box;
	}

	.form-settings {
		width: 20%;
		background-color: $darkGreen;

		padding: 1em;
		gap: 1em;

		div form {
			display: flex;
			flex-direction: column;

			gap: 0.5em;
		}
		.Component-Atr {
			border: none;
			outline: none;
			color: white;
			background: rgba(0, 0, 0, 0.5);
			font-size: 0.8rem;
			font-family: 'Calibre';
		}
	}
	.workflow-builder-sidebar {
		width: $sidebarWidth;
		background-color: $darkGreen;

		padding: 1em;
		gap: 1em;

		div form {
			display: flex;
			flex-direction: column;

			gap: 0.5em;
		}
		.Component-Atr {
			border: none;
			outline: none;
			color: white;
			background: rgba(0, 0, 0, 0.5);
			font-size: 0.8rem;
			font-family: 'Calibre';
		}
	}
	.workflow-builder-container {
		width: calc(100vw - #{$sidebarWidth});
		min-width: 1000px;
		height: 100%;
		background-color: #ffffff;
		overflow: auto;
		padding: 12px 32px;
		display: flex;
		flex-direction: column;
		.workflow-builder-bread-crumbs {
			color: #fb7a03;
			margin-bottom: 8px;
		}

		.workflow-builder-bar {
			height: 56px;
			padding: 12px 24px;
			background-color: #28383c;
			width: 100%;
			border-radius: 4px;
		}
		.worklfow-editor-container {
			overflow-y: auto;
			margin-top: 12px;
			display: flex;
			flex-direction: column;
			flex: 1;
			gap: 12px;

			.workflow-editor-header {
				background: #f7f9fa;
				padding: 18px;
			}

			.workflow-editor-body {
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 18px;
				justify-content: center;
				align-items: center;
				background: #eef2f3;
				box-shadow: 0px 7px 29px 0px #64646f33;
			}
			.board {
				border: 1px dotted grey;
				padding: 1em;
				min-height: 100% !important;
				height: fit-content !important;
			}
		}
	}
	.builder {
		width: 60%;
		height: 100%;
		background-color: #ffffff;
		// border: 3px solid blue;
		overflow: hidden;

		.builder-bar {
			height: 2.5em;
			// border: 2px solid red;
			padding-left: 0.5em;
			padding-right: 0.5em;
			background-color: $backgroundGrey;
		}

		.Editor {
			min-height: fit-content;
			height: calc(100% - 2em);
			overflow-y: auto;

			.board {
				border: 1px dotted grey;
				padding: 1em;
				min-height: 100% !important;
				height: fit-content !important;
			}
		}
	}
	.form-components {
		width: 20%;
		background-color: $darkGreen;
		gap: 1em;
		padding: 1em;

		.components-box {
			gap: 0.5em;
		}
	}
}

.field-1,
.field-2,
.field-3,
.field-4 {
	.field-box {
		background: rgba(0, 0, 0, 0.5);
		border-radius: 3px;
		width: 50%;
		height: 3.5em;
		gap: 0.2rem;
		// border: 2px solid red;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.2rem;

			background: transparent;
			outline: none;

			height: 100%;
			width: 100%;
		}
	}
}

.card {
	border: none !important;
	min-height: fit-content !important;
	// overflow-y: auto;
}

.hoverDelete:hover {
	#removeOption {
		display: block;
	}
}
