@import '../../style-assets/colors';

.TeamMembersAvailability {
	display: flex;
	flex-direction: column;
	width: 613px;
	height: 314px;
	gap: 14px;
	padding: 16px;
	border-radius: 4px 0px 0px 0px;
	box-shadow: 0px 7px 29px 0px #64646f33;
	background: #eef2f3;
	box-sizing: border-box;
}

.weekly-daily-bar {
	padding: 8px 12px 8px 12px;
	border-radius: 8px;
	background: #f7f9fa;
}

.TeamMembersAvailability table {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border-radius: 4px;
	background: #ffffff;

	thead {
		position: sticky;
		top: 0;
		background: #ffffff;
		@extend .BodyTwoLight;

		th {
			color: $lightText !important;
			padding: 13px 16px 8px 16px;
			gap: 4px;
		}
	}
	tbody {
		background: #f7f9fa;
		overflow: auto;
		@extend .BodyTwoLight;

		tr {
			border-bottom: 4px solid white;
		}

		td {
			padding: 13px 16px 8px 16px;
		}
	}
}
