@import '../../style-assets/colors';

.archieve-team-modal {
	width: 850px;
	background-color: $backgroundWhite;
}

.archieve-team-modal-body {
	padding: 16px;

    height: fit-content;
    max-height: 80vh;
	width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    background-color: $formbg;

    .warning-message{
        display: flex;
        flex-direction: column;

    }

    .fixed-header{
        position: sticky;
        top: 40px;
        z-index: 1;
    }
}
