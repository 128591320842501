.main-task-container {
	display: flex;
	flex-direction: column;
	/* width: 100%; */
	width: calc(100vw - 260px);
	margin-left: 200px;
}

.workflow-container {
	// min-height: 18px;
	// max-height: max-content;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px 8px;
}
.task-card-container-lg {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	gap: 2px;
	// padding-left: 8px;
	// padding-right: 8px;
	padding: 8px;
	box-sizing: border-box;
    .MuiSvgIcon-root{
        width: 16px !important;
        height: 16px !important;
    }
}

.task-shadow{
	background-color: white;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 13px 8px rgba(0, 0, 0, 0.05),
		0px 6px 6px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1),
		0px 0px 0px rgba(0, 0, 0, 0.1);
}

.task-card-container {
	text-align: left;
	background-color: white;
	border: 0.1rem solid #eaeaea;
	// border-radius: 0 0 0.3rem 0.3rem;
	// margin: 0.8rem;
	margin-top: 0;
	padding: 0;
	cursor: pointer;
	position: relative;
	// min-width: 25rem;
	width: 100%;
	// height: auto;
	// min-height: 10.97vh;
	height: 100%;
	/* width: 100%; */
	/* min-width: 25rem; */
	//   ${transition(['border', 'background-color'])}
}

.status-wrapper {
	margin-left: -0.1rem;
}

.task-status {
	display: inline-block;
	padding: 0px 0.4rem;
	border-bottom-right-radius: 3px;

	&.COMPLETED {
		background-color: #77c3de;
	}
	&.PAST_DUE {
		background-color: #f2be1b;
	}
	&.BLOCKED {
		background-color: #f05544;
	}
	&.IN_PROGRESS {
		background-color: #18c58d;
	}
	&.APPROVAL_PENDING {
		background-color: #fb7a03;
	}
}

// .client-task-wrapper {
// 	margin-top: 4.1rem;
// 	width: 100%;
// 	${transition(['width'])};

// 	/* height: calc(100vh - 3.2rem);
// 	overflow: auto; */

// 	&.task-detail-open {
// 	  width: calc(100vw - ${taskDetailsWidth + sidebarWidth}px);
// 	}
// }
// }
.content {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 768px) {
	/* styles for screens with width less than 1300px go here */
	.task-card-due-date{
		display: none;
	}
  }