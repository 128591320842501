@import '../../style-assets//misc';
@import '../../style-assets//colors';

.NavBar {
	position: relative;

	height: $navbarHeight;
	width: $sidebarWidth;

	background-color: rgba(0, 0, 0, 0.25);

	display: flex;
	justify-content: space-between;

	box-sizing: border-box;

	.flex1 {
		display: flex;
		justify-content: center !important;
		align-items: center !important;
		gap: 1em;

		color: $offWhite;

		.Icon {
			background-color: black;
			display: flex;
			justify-content: center;

			height: 100%;
			width: 50px;
			padding: 0.6em;
		}
		// h4 {
		//   font-family: "Calibre-Semibold";
		//   font-size: 1rem;
		//   margin: 0;
		//   padding: 0;
		// }
	}

	.flex2 {
		display: flex;
		align-items: center;

		padding: 0.7em;

		background-color: transparent;
		color: $primaryColor;

		border: none;
		outline: none;
	}
	.flex2:hover {
		cursor: pointer;
	}
}

.LogoutContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0em 1em 0em 1em;
	box-sizing: border-box;
}

.User-Logout {
	display: flex;
	align-items: center;
	gap: 0.8em;
}
.LogoutContent {
	font-family: 'Calibre';

	h6 {
		font-family: 'Calibre';
		font-style: normal;
		font-size: 1.1rem;
		padding: 0;
		margin: 0;
	}
	p {
		font-family: 'Calibre';
		font-style: normal;
		font-size: 0.7rem;
		padding: 0;
		margin: 0;
	}
}
