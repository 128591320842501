@import '../../style-assets/colors';

.workflow-card {
	display: flex;
	flex-direction: column;
	justify-content: center;

	height: 210px;
	width: 352px;
	border-radius: 0px;
	padding: 24px 21px 24px 21px;

	gap: 12px;

	background-color: $backgroundGrey;

}
