@import './main';
@import '../styles/style-assets/colors';
// html {
// 	width: 100%;
// 	height: 100%;
// 	-ms-text-size-adjust: 100%;
// 	-webkit-text-size-adjust: 100%;
// 	font-size: 62.5%; /* such that 1rem === 10px */
// }
body {
	display: flex;
	justify-content: center;
	// border: 2px solid red;
}
html {
	scroll-behavior: smooth;
}
html:focus-within {
	scroll-behavior: smooth;
}
.App {
	text-align: center;
	// height: 100vh;
	// max-width: 2200px;
	// width: 100vw;
	// min-width: calc(260px + 1300px);
	overflow: hidden;
	// border: 2px solid yellow;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

input,
textarea {
	outline: none;
	// border: none;
	// border-bottom: 1px solid #28383C;
}
//
input[type='radio'] {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: end;
	/* margin: 1rem 1rem 1rem 0; */

	appearance: none;
	cursor: pointer;
	height: 12px;
	width: 12px;
	border: 1px solid white !important;
	outline: 1px solid $placeholderText !important;

	border-radius: 50%;
	/* box-shadow: inset 0 0 0 2px #fff; */
	flex-shrink: 0;

	&:checked {
		background: #fb7a03;
		border: 1px solid #fb7a03;
	}
}

//

/* Track */
*::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 20px;
}
/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
	background: #555;
}

*::-webkit-scrollbar {
	width: 8px;
}
*::-webkit-scrollbar:horizontal {
	height: 4px;
}

* {
	scrollbar-width: thin;
}

.invisible-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Chrome, Safari, Edge, Opera */
.hide-number-arrows::-webkit-outer-spin-button,
.hide-number-arrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.hide-number-arrows[type='number'] {
	-moz-appearance: textfield;
}

.task-drag-icon {
	color: #78798296;
	cursor: grab;
}

.task-drag-icon:hover {
	color: $labelColor;
}

body > iframe {
	pointer-events: none;
}

.ellipse {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pac-container {
	z-index: 9999;
}

.notes-modal .ql-container,
.notes-modal h3 {
	// height: calc(100% -1em);
	// height: ;
}
.hide-scrollbar {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera */
}
