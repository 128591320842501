.new-workflow-card {
    // width: fit-content;
    width: 350px;
    // max-width: 370px;
    height: auto;
    min-height: 190px;
	max-height: 235px;
    background-color: white;
    border-radius: 4px;
    padding: 12px;

    
    .description-container{
        width: 100%;
        height: 130px;
        padding: 12px;
        background-color: #EEF2F3;
        border-radius: 8px;
    }
}

.new-workflow-card-disabled{
    opacity: 0.5;
}