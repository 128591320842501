.mentions {
	margin: 1em 0;
}

.mentions--singleLine .mentions__control {
	display: inline-block;
	width: 130px;
}
.mentions--singleLine .mentions__highlighter {
	padding: 1px;
	border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
	padding: 1px;
	border: 2px inset;
}

.mentions--multiLine .mentions__control {
	font-family: monospace;
	font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
	padding: 0 0.5rem;
	border: 0;
	font-size: 12pt;
	font-family: 'ApercuRegular', Arial, sans-serif;
}
.mentions--multiLine .mentions__input {
	padding: 0 0.5rem;
	outline: 0;
	border: 0;
	font-size: 12pt;
	font-family: 'ApercuRegular', Arial, sans-serif;
}

.mentions__suggestions__list {
	background-color: white;
	font-size: 10pt;
}

.mentions__suggestions__item {
	padding: 5px 15px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
	background-color: #cee4e5;
}

.mentions__mention {
	position: relative;
	z-index: 10;
	color: #007bff;
	pointer-events: none;
}
