@import '../../style-assets/colors';

.ViewHtmlParserContent {
	width: 500px;
	border-radius: 4px;

	.main {
		display: flex;
		flex-direction: column;

		height: 85%;

		gap: 24px;
		padding: 24px;
		border-radius: 4px 4px 0px 0px;

		background: $formbg;
	}

	.ctas {
		height: 15%;

		padding: 16px 24px 16px 24px;
		border-radius: 0px 0px 4px 4px;

		background: $rowColor;
	}
}
