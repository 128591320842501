.week-view-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background: white;
	height: 42px;
	// position: fixed;
	width: calc(100vw - 260px);
	z-index: 1;
}

.date-wrapper {
	display: flex;
	width: 100%;
}

.date-format {
	color: #696f6c;
	font-size: 1.2rem;
	line-height: 1.6rem;
	width: 100%;
	height: 42px;
	// border: 0.1rem solid #fcfbf7;
	border-right: none;
	background-color: white;
	// border-bottom: 1px solid #e9e7e1;
	display: flex;
	align-items: center;
	justify-content: center;

	&:last-child {
		// border-right: 0.1rem solid #e9e7e1;
	}

	&.current-day {
		background-color: #eaeaea;
		/*
    + h4 {
      border-left-color: grey;
    } */
	}
}
