@import '../../style-assets/colors';

.workflow-grid-view {
	width: 100%;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
}

.workflow-list-view {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.worklfow-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 55px;
	background-color: $backgroundGrey;
	padding: 16px 20px 16px 20px;
	box-sizing: border-box;
}
