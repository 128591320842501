.progress-bar-modal-container {
	position: fixed;
	bottom: 0;
	right: 0;
	margin-bottom: 5px;
	margin-right: 8px;
	border-radius: 4px 4px 0px 0px;
	min-width: 380px;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	background: $formbg;
	box-shadow: 0 0 15px 5px rgba(75, 75, 75, 0.2);

	.progress-bar-modal {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $backgroundWhite;
		width: 100%;

		.progress-bar-header {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			width: 100%;
			background: $rowColor;
			align-items: center;
			padding: 0px 9px;
		}
	}
	.progress-bar-body {
		max-height: 380px;
		overflow-y: auto;
	}
}
