#analytics{
    margin-top:23px;
    padding:0px 64px 0px 32px;
    .heading{
        display: flex;
        justify-content: start;
        padding: 0.6rem;
        background: $backgroundGrey;
        border-radius: 3px;
        .text{
            margin-top: 0.6rem;
        }
 
    }
    .body{
        padding-left: 15px;
        padding-right: 15px;
       
    }
}
.css-1km1ehz{
    font-family: 'Centra-No2' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.015em !important;
    color: #333333 !important;
}

.css-yb0lig{
    font-family: 'Centra-No2' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.015em !important;
    color: #333333 !important;
}