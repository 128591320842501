.custom-tooltip-container {
	.sub-key-title{
		color: $primaryColor;
		
	}
	.lagging-by{
		color: $labelColor;
	}
	.tasks-list-container{
		.sub-category{

		}
		ul {
			list-style: none; /* Remove default bullets */
		}
		ul li::before {
			content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
			color: $skyBlue; /* Change the color */
			font-weight: bold; /* If you want it to be bold */
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
			margin-left: -1em; /* Also needed for space (tweak if needed) */
		}
	}
	// .data-list {
	// 	.client-name{
	// 		color: $primaryColor;
	// 	}
	// 	ul {
	// 		list-style: none; /* Remove default bullets */
	// 	}
	// 	ul li::before {
	// 		content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
	// 		color: $skyBlue; /* Change the color */
	// 		font-weight: bold; /* If you want it to be bold */
	// 		display: inline-block; /* Needed to add space between the bullet and the text */
	// 		width: 1em; /* Also needed for space (tweak if needed) */
	// 		margin-left: -1em; /* Also needed for space (tweak if needed) */
	// 	}
	// }
}

.analytics-container{
	padding: 18px;
	width: 100%;
	overflow-x: auto;
	.filters-container{
		display: flex;
		align-items: start;
		height: auto;
		min-height: 46px;
		background-color: #FCFDFD;
		border-radius: 4px;
		justify-content: space-between;
		padding: 12px 18px;
		width: 100%;
		.filter-title{
			display: flex;
			align-items: center;
			padding-top: 6px;
			.group-by-filter{
				align-items: center;
				display: flex;
				.MuiSelect-select:focus{
					visibility: hidden;	
				}
			}
			img{
				width: 14px;
				height: 14px;
				margin-right: 5px;
			}
		}
		.filters-box{
			display: flex;
			flex-direction: column;
			.group-by-filter{
				align-items: center;
				display: flex;
				.MuiSelect-select:focus{
					visibility: hidden;	
				}
			}
			.clear-filters{
				color: $primaryColor;
				align-self: center;
				cursor: pointer;
			}
		}
	}
	
	.analytics-box{
		text-align: left;
		width: 100%;
		background-color: #FCFDFD;
		border-radius: 4px;
		margin-top:5px;
		padding: 18px;
		display: grid;
		place-items: center;
		overflow-x: auto;
	}
	.graphs-data{
		width: 40%;
		// max-width: 400px;
		min-width: 200px;
		height: 536px;
		margin-left: 12px;
		.stats-title-container{
			height: 62px;
			display: flex;
			padding: 12px 18px;
			align-items: center;
			background-color: #FCFDFD;
			border-radius: 4px;
		}
		.data-container{
			margin-top: 5px;
			border-radius: 4px;
			background-color: #FCFDFD;
			height: 100%;
			padding: 18px;
			.custom-tooltip-container{
				overflow:auto;
				max-height: 500px;
			}
		}
	}
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
	font-weight: 300 !important;
	font-size: 12px !important;
	font-family:'Centra-No2' !important;
}

.css-10hburv-MuiTypography-root{
	font-weight: 300 !important;
	font-size: 12px !important;
	font-family:'Centra-No2' !important;
}