.workflow-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.workflow-modal-header {
		display: flex;
		padding: 12px;
		position: absolute;
		top: 0;
		background-color: #eef2f3;
		width: 100%;
		z-index: 999;
	}
	.workflow-modal-body {
		width: 70vw;
		max-height: 80vh;
		overflow: auto;
		background-color: #eef2f3;
		border-radius: 4px 4px 0px 0px;
		padding: 96px 24px 88px 24px;
	}

	.workflow-modal-footer {
		position: absolute;
		bottom: 0;
		height: 64px;
		background-color: #f7f9fa;
		border-radius: 0px 0px 4px 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		width: 100%;
	}
}

.task-section {
	width: 100%;
	height: fit-content;
	background-color: #f7f9fa;
	border-radius: 4px;
	padding: 24px;
	margin-top: 16px;

	.task-section-body {
		background-color: #fcfdfd;
		border-radius: 8px;
		gap: 24px;
		padding: 12px;
		margin-top: 24px;
	}
}
