@import '../../style-assets/colors';

.PreviousClientDocumentsRequests {
	min-width: 352px;
	width: 80%;

	border-radius: 4px;

	.main {
		display: flex;
		flex-direction: column;

		height: 85%;

		gap: 24px;
		padding: 24px;
		border-radius: 4px 4px 0px 0px;

		background: $formbg;
	}

	.ctas {
		height: 15%;

		padding: 16px 24px 16px 24px;
		border-radius: 0px 0px 4px 4px;

		background: $rowColor;
	}
}

.PreviousClientDocumentsRequests {
	.table-wrapper {
		box-sizing: border-box;
		overflow: auto;
		height: 300px;
		background: white;
		border-radius: 4px;
	}

	table {
		width: 100%;
		box-sizing: border-box;
		border-radius: 4px;
		background: #ffffff;

		thead {
			position: sticky;
			top: 0;
			background-color: $rowColor;
			z-index: 1;
			th {
				@extend .BodyTwoLight;
				color: $lightText !important;
				padding: 13px 16px 8px 16px;
				gap: 4px;
			}
		}
		tbody {
			background: #f7f9fa;
			overflow: auto;
		}

		tr {
			@extend .BodyTwoLight;
			border-bottom: 4px solid white;
		}

		td {
			padding: 13px 16px 8px 16px;
			word-wrap: break-word;
		}
	}
}
