.overlap-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 250px !important;
	gap: 24px;
	// border: 2px solid blue;
}

.length-1 {
	height: 75px;
	// border: 2px solid red;
}
.length-2 {
	height: 95px;
	// border: 2px solid blue;
}
.length-3 {
	height: 110px;
	// border: 2px solid green;
}

.overlap-tasks-container {
	position: relative;
	display: flex;
	width: 100%;
	max-width: 250px !important;
	// border: 2px solid red;

	.task-1 {
		position: absolute;
		width: 100%;
		box-sizing: border-box;
		// pointer-events: none;
	}
	.task-1-height {
		max-height: 110px;
		min-height: 110px;
	}

	.task-2 {
		position: absolute;
		width: 90%;
		box-sizing: border-box;
		// pointer-events: none;
	}
	.task-2-height {
		max-height: 95px;
		min-height: 95px;
	}

	.task-3 {
		position: absolute;
		width: 80%;
		box-sizing: border-box;
		// pointer-events: none;
	}
	.task-3-height {
		max-height: 75px;
		min-height: 75px;
	}

	.task2-step-forward-1 {
		right: 0%;
	}

	.task3-step-forward-1 {
		right: 10%;
	}

	.task3-step-forward-2 {
		right: 0%;
	}
}

// .overlap-tasks-container > div:hover {
// 	z-index: 1;
// }

.overlap-tasks-container-extended {
	width: 100%;
	max-width: 250px !important;
	// display: flex;
	flex-direction: column-reverse;
	gap: 12px;
	height: auto;
	// position: static;
	border-radius: 4px;

	.task-1,
	.task-2,
	.task-3 {
		width: auto;
		position: static;
	}
}

.animation {
	transition: all 0.5s ease-in-out;
}
