.payment-info {
	input {
		margin-bottom: 15px;
	}
	.containImage {
		position: relative;
		padding: 0;
		margin: 0;
	}
	.containText {
		height: 20px;
		margin: 0;
	}
	.imageItself1 {
		position: absolute;
		bottom: 23px;
		left: 78%;
	}
	.imageItself2 {
		position: absolute;
		bottom: 23px;
		left: 83%;
	}
	.imageItself3 {
		position: absolute;
		bottom: 23px;
		left: 88%;
	}
	.imageItself4 {
		position: absolute;
		bottom: 23px;
		left: 94%;
		width: 15px;
	}
	.imageItself5 {
		position: absolute;
		bottom: 23px;
		left: 87%;
		width: 15px;
	}
	.imageItself6 {
		position: absolute;
		bottom: 2px;
		left: 87%;
		width: 15px;
	}
	.error {
		margin-top: -10px;
	}
}

.form-control-payment {
	height: 26px !important;
	background: #f6f5f2 !important;
	border-radius: 3px 3px 0px 0px !important;
	border: 0 !important;
	border-bottom: 1.5px solid black !important;
	width: 100% !important;
}

.confirm-plan {
	.plans {
		display: flex;
		justify-content: center;
	}
	.selected {
		background: #fb7a03;
		color: '#ffffff';
		padding: 40px 16px 16px 16px;
		border: 1px solid #eaeaea;
		box-sizing: border-box;
		border-radius: 3px;
	}

	.notSelected {
		background: #ffffff;
		padding: 40px 16px 16px 16px;
		border: 1px solid #eaeaea;
		box-sizing: border-box;
		border-radius: 3px;
	}
	button {
		background: #fb7a03;
		color: #ffffff;
		width: 268px;
		height: 32px;
		border-radius: 3px;
		margin-top: 16px;
	}
	li {
		margin-left: 16px;
	}
}
