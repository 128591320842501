.color-picker {
	position: absolute;
	z-index: 2;
	width: 300px;
	top: 30px;
	right: 10px;
	background-color: #eef2f3;
	border-radius: 4px;
	box-shadow: 0px 8px 24px 0px #959da533;
	.color-picker-header {
		padding: 12px 0 0 12px;
	}
	.color-picker-body {
		padding: 12px 16px;
	}
	.color-picker-footer {
		background-color: white;
		height: 64;
		padding: 16px 24px;
	}
	.color-picker-error {
		padding: 0 16px 10px 16px;
	}
}
