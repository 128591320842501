.topBar{
    padding-left:31px; 
    background:$darkWhite;
    .companyName{
        margin-left: -31px;
        display: flex;
        justify-content: start;
        background: $backgroundGrey;
        .avatar{
            margin:7px 0px 7px 31px;
            width:28px;
            height:28px;
            background: $secondaryButtonGrey;
            border-radius:50% ;
            display: flex;
            justify-content: center;
            align-items: center;
            .text{
                font-size: 16px;
                line-height: 23px;
            }
        }
        .title{
            align-self: center;
            margin-left: 7px;
        }
    }
    .bar{
        display: flex;
        justify-content: start;
        .tab{
            margin-right: 52px;
            .tabLabel{
                margin:0 0.7rem;
                a{
                    text-decoration: none;
                    color:$black ;
                }
            }
            .companyTabActive{
                height:3px;
                width: 100%;
                
                background: $primaryColor;
            }
        }
        
    }
   
}