.toggleSwitchButton{
    width: 236px;
    height: 26px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    padding: 7px;
    align-items: center;
}
.switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 5px;
    top: 4px;

  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 0px;
    bottom: -3px;
    background-color: #FB7A03;
    -webkit-transition: .1s;
    transition: .1s;
  }
  
  input:checked + .slider {
    background-color: #000000;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #000000;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  

  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }