.ContactAdd-Form {
	width: 100%;
	height: 100%;
	min-height: 20vh;
	position: relative;

	.VerticalSpacer {
		input,
		textarea {
			font-family: 'Calibre';
			background-color: $backgroundGrey;
			border: none;
			border-bottom: 1px solid #333;
		}
		label {
			font-family: 'Calibre';
			font-size: 0.8rem;
			color: $labelColor;
			text-transform: uppercase;
			margin-bottom: $spacing16b;
		}
	}
}
