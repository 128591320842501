@import '../../style-assets/colors';

.workflow-types-list {
	a {
		text-decoration: none;
		color: white;
	}

	.dot {
		border: 3px solid $offWhite;
		border-radius: 10px;
	}

	.workflow-category-link:hover {
		a {
			text-decoration: none;
			text-align: left;
			color: $primaryColor;
		}
		.dot {
			border: 3px solid $primaryColor;
		}
	}

	.active {
		a {
			text-decoration: none;
			text-align: left;
			color: $primaryColor;
		}
		.dot {
			border: 3px solid $primaryColor;
		}
	}
}
