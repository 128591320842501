@import '../../style-assets/colors';

.CompanyNavbar {
	width: 100%;
	background-color: $darkWhite;
	height: 45px;
	min-height: 2.5em;

	position: sticky;
	top: 0;

	border: 1px solid #eaeaea;

	display: flex;
	align-items: center;
	z-index: 1000;

	.CompanyNavbar-Container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		gap: 2.5em;
		align-items: center;
		// border: 2px solid red;

		margin: auto;
		padding: 0 1.8em 0 2em;

		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: $lightdarkText;
			margin: 0 !important;
			padding: 0 !important;
			height: 100%;
			// border: 2px solid red;
		}
		.active {
			border-bottom: 3px solid $primaryColor;
			padding-bottom: 2px;
			color: $black;
		}
	}
}
