@import '../../../style-assets/colors';

.WorkflowModal {
	overflow: auto;
	padding: 0;
	height: 85vh;
	width: 80vw;
	max-width: 1300px;
	max-height: 750px;
	margin: 0;

	display: flex;
	align-items: center;
	flex-direction: column;
	z-index: 1;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.WorkflowModal .workflowModalContent {
	background-color: #fff;
	height: 85vh;
	width: 80vw;
	max-width: 1300px;
	max-height: 750px;
	padding: 1em 2em 1em 2em;

	display: flex;
	flex-direction: column;
	gap: 1.5em;

	overflow-y: auto;
	box-sizing: border-box;

	.workflowModalBox {
		display: flex;
		flex-direction: column;
		gap: 0.8em;
	}

	.workflow-list {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
	}

	.workflow-list-item {
		background-color: $backgroundGrey;
		padding: 0.5em 1em 0.5em 1em;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.footer-buttons {
		justify-self: end;
	}

	.light-search-bar::placeholder {
		color: #333;
	}
}

.create-new-workflow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 24px;
	gap: 24px;

	width: 214px;
	height: 68px;

	background: #f9faff;
	border-radius: 3px;

	h4 {
		font-family: 'Calibre';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
	}
}
