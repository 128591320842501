@import '../../../style-assets/colors';

.TabWrapper {
	display: flex;
}

.Tab {
	border: 1px solid $primaryColor;
	border-right: none;
	width: 33.33333%;
	background-color: white;
	font-family: 'Calibre-Semibold';
	font-size: 1.1rem;
	text-align: center;
	cursor: pointer;

	&:first-child {
		border-radius: 3px 0 0 3px;
	}

	&:last-child {
		border-radius: 0 3px 3px 0;
		border-right: 1px solid $primaryColor;
	}

	color: $primaryColor;

	&.selected {
		background-color: $primaryColor;
		color: white;
	}

	&:focus {
		outline-offset: 0.2rem;
	}
}

.Recurrence-text {
	background-color: white;
	padding: 1em 1.5em 1em 1.5em;
}
