// Kindly press Ctrl+A and then press Ctrl+/ to use the code

// here the typography is defined

@import '../mixins/typegraphyMixin';
@import '../style-assets/colors';
@import '../style-assets/spacing';

// @mixin typography($fontFamily, $fontSize, $fontWeight, $letterSpacing, $lineHight, $color)
.errorMessage {
	@include typography(Calibre, 400, 14px, 16px, 0, #f05544);
}
.successMessage {
	@include typography(Calibre, 400, 14px, 16px, 0, #18c58d);
}
.calibre-14-16 {
	@include typography(Calibre, 400, 14px, 16px, 0, black);
}
.calibre-12-14 {
	@include typography(Calibre, 400, 12px, 14px, 0, black);
}

.pointer {
	&:hover {
		cursor: pointer;
	}
}

.default-cursor {
	&:hover {
		cursor: default;
	}
}

.hover-pointer:hover {
	cursor: pointer;
	background-color: $darkBackground;
}

.input-font {
	font-size: 0.8rem;
}

.letter-space-5 {
	letter-spacing: 0.5px;
}

.primary-text {
	color: $primaryColor !important;
}
.table-header-text {
	color: #7c7c7c;
}

.opacity-05 {
	opacity: 0.5;
}

.white-text {
	color: white;
}

.red-text {
	color: $redcolor !important;
}

.green-text {
	color: $greenColor !important;
}

.dark-text {
	color: #333333;
}

.light-dark {
	color: $lightdarkText;
}

.light-text {
	color: $lightText;
}

.red-text {
	color: red;
}

.green-color {
	color: #8ea56a;
}

.font-calibre-Semibold {
	font-family: 'Calibre-Semibold';
}

.font-calibre {
	font-family: 'Calibre';
}

.placeholder-text {
	color: $placeholderText;
}

.focus-outline:focus {
	outline: 1px dotted #fb7a03;
}

// .input-style {
// 	background-color: $backgroundGrey;
// 	border: none;
// 	border-bottom: 1px solid #28383c;
// }

.border-none {
	border: none !important;
}

.BodyThreeB {
	font-family: 'Calibre';
	font-weight: normal;
	font-style: normal;
	font-size: 0.8rem;
	line-height: 0.9rem;
	margin: 0;
}

.error-text {
	color: red;
}

.light-text {
	color: $lightText;
}
.light-grey-text {
	color: $lightgrey;
}

.HeadlineOneBook {
	font-family: 'Centra-No2';
	font-weight: Book;
	font-size: 28px;
	line-height: 31px;
	margin: 0 !important;
	padding-top: 8px !important;
}
.HeadlineOneBold {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 28px;
	line-height: 31px;
	margin: 0 !important;
	padding-top: 9px !important;
	// border: 2px solid red
}

.HeadlineTwoBook {
	font-family: 'Centra-No2';
	font-weight: Book;
	font-size: 24px;
	line-height: 23px;
	margin: 0 !important;
	padding: 0 !important;
	padding-top: 5px !important;
}
.HeadlineTwoBold {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 24px;
	line-height: 23px;
	margin: 0 !important;
	padding: 0 !important;
	padding-top: 7px !important;
}

.HeadlineThreeBook {
	font-family: 'Centra-No2';
	font-weight: Book;
	font-size: 20px !important;
	margin: 0 !important;
	line-height: 23px;
	padding: 0 !important;
	padding-top: 5px !important;
}
.HeadlineThreeBold {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 20px !important;
	margin: 0 !important;
	line-height: 23px;
	padding: 0 !important;
	padding-top: 5px !important;
}
.BodyOneLight {
	font-family: 'CentraLight';
	font-weight: light;
	font-size: 16px !important;
	margin: 0 !important;
	line-height: 20px;
	padding: 0 !important;
	padding-top: 5px !important;
	// border: 2px solid green;
}
.BodyOneBold {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 16px !important;
	line-height: 20px;
	margin: 0 !important;
	padding: 0 !important;
	padding-top: 5px !important;
	// border: 2px solid red !important;
}

.BodyTwoLight {
	font-family: 'CentraLight';
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 18px;
	// margin: 0 !important;
	// padding: 0 !important;
	// padding-top: 3px !important;
	// border: 2px solid red !important;
}
.BodyTwoBold {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 14px !important;
	// margin: 0 !important;
	line-height: 18px;
	// padding: 0 !important;
	// padding-top: 5px !important;
}

.BodyThreeLight {
	font-family: 'CentraLight';
	font-weight: light;
	font-size: 12px !important;
	// margin: 0 !important;
	line-height: 16px;
	// padding: 0 !important;
	// padding-top: 5px !important;
}
.BodyFourLight {
	font-family: 'CentraLight';
	font-weight: light;
	font-size: 10px !important;
	// margin: 0 !important;
	line-height: 16px;
	// padding: 0 !important;
	// padding-top: 5px !important;
}
.BodyThreeBold {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 12px !important;
	// margin: 0 !important;
	line-height: 16px;
	// padding: 0 !important;
	padding-top: 2px !important;
}

.DescriptionLight {
	font-family: Inter;
	font-weight: light;
	color: $labelColor;
	font-size: 16px !important;
	margin: 0 !important;
	line-height: 20px;
	padding: 0 !important;
	padding-top: 5px !important;
}

.DescriptionOne {
	font-family: Inter;
	color: $darkText;
	font-size: 18px !important;
	margin: 0 !important;
	line-height: 20px;
	padding: 0 !important;
	padding-top: 5px !important;
}

.DescriptionTwo {
	font-family: Inter;
	color: $darkText;
	font-size: 20px !important;
	margin: 0 !important;
	line-height: 20px;
	padding: 0 !important;
	padding-top: 5px !important;
}

.DescriptionThree {
	font-family: Inter;
	color: $darkText;
	font-size: 16px !important;
	margin: 0 !important;
	line-height: 20px;
	padding: 0 !important;
	padding-top: 5px !important;
}
