@import '../../style-assets/colors';

.search-modal-main {
	outline: none;
	height: 500px;
	width: 445px;
	overflow-y: auto;

	display: flex;
	flex-direction: column;

	gap: 10px;
}

.main-first-child {
	display: flex;
	flex-direction: column;
	gap: 7.5px;
}
.search-outer {
	background: $backgroundColor;
	height: 56px;
	width: 444px;
	border-radius: 8px;

	display: flex;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;
}
.search-inner {
	height: 32px;
	width: 420px;
	border-radius: 8px;
	background: $darkBackground;

	display: flex;
	align-items: center;

	padding-left: 10px;

	box-sizing: border-box;
}

.main-second-child {
	height: 466px;
	width: 444px;
	border-radius: 8px;
	padding: 16px;
	background: $backgroundColor;

	display: flex;
	flex-direction: column;
	gap: 16px;

	overflow-y: auto;
	box-sizing: border-box;
}
