@import '../../style-assets//misc';
@import '../../style-assets//colors';

.SideBar {
	width: $sidebarWidth;
	height: 100vh;

	background-color: $bgSidebar;

	box-sizing: border-box;
	// border: 2px solid green;
}

// SideBar Components

.SidebarContentWrapper {
	display: flex;
	flex-direction: column;
	gap: 2em;
	justify-content: space-between;
	width: 100%;
	height: calc(100% - #{$navbarHeight});
	padding: 16px 12px;
	box-sizing: border-box;
	// border: 2px solid red;

	overflow: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.new-sidebar-content-wrapper,
.SidebarContentWrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.new-sidebar-content-wrapper,
.SidebarContentWrapper {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.SidebarListWrapper {
	display: flex;
	flex-direction: column;
	// margin-bottom: $spacing16;
	// border: 2px solid red;
}

.FilterSpacing-1 {
	display: flex;
	flex-direction: column;

	gap: 12px;
}

.side-menu {
	a {
		text-decoration: none;
		color: white;
	}

	.dot {
		border: 3px solid transparent;
		border-radius: 10px;
	}

	.side-menu-link:hover {
		a {
			text-decoration: none;
			text-align: left;
			color: $primaryColor;
		}
		.dot {
			border: 3px solid transparent;
		}
	}

	.active {
		a {
			text-decoration: none;
			text-align: left;
			color: $primaryColor;
		}
		.dot {
			border: 3px solid $primaryColor;
		}
	}
}

.new-sidebar-content-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: calc(100% - #{$navbarHeight});
	box-sizing: border-box;
	overflow: auto;
	// padding: 12px 0px;
	// border: 2px solid red;

	.new-sidebar-item {
		width: 100%;
		padding: 8px 12px 8px 12px;
		cursor: pointer;
		// border: 2px solid red;
	}

	.new-sidebar-item-header {
		width: 100%;
		padding: 8px 12px 8px 12px;
	}

	.nav-item-side-line {
		width: 6px;
		height: 100%;
		border-radius: 0px 4px 4px 0px;
		background: $primaryColor;
	}

	.nav-item-side-line-draft {
		width: 6px;
		height: 100%;
		border-radius: 0px 4px 4px 0px;
		background: #f2be1b;
	}
}

.new-side-menu {
	display: flex;
	flex-direction: column;
	// gap: 8px;
	// border: 2px solid green;
	width: 100%;
	background: #EEF2F3;

	.active + .main-sidebar-item:not(.active) {
		border-top-right-radius: 10px; /* Rounded top-right corner for the item before the active one */
	}

	.active + .main-workflow-sidebar-item:not(.active) {
		border-top-right-radius: 10px; /* Rounded top-right corner for the item before the active one */
	}

	.main-sidebar-item {
		background: $bgSidebar;
	}

	.main-workflow-sidebar-item {
		background: #364e55;
	}

	.prev {
		border-bottom-right-radius: 10px;
	}

	a {
		text-decoration: none;
		color: white;
	}

	.dot {
		border: 3px solid transparent;
		border-radius: 10px;
	}
	.dot-draft {
		border: 3px solid transparent;
		border-radius: 10px;
	}

	.new-sidebar-item:hover {
		// border: 2px solid red;
		background: #eef2f3;
		a {
			font-family: 'Centra-bold';
			font-weight: 700 !important;
			text-decoration: none;
			text-align: left;
			color: #333333 !important;
		}
		.dot {
			border: 3px solid $primaryColor;
		}
		.dot-draft {
			border: 3px solid #f2be1b;
			border-radius: 4px;
		}
	}

	.active {
		// border: 2px solid red;
		background: #eef2f3;
		a {
			font-family: 'Centra-bold';
			font-weight: 700 !important;
			text-decoration: none;
			text-align: left;
			color: #333333 !important;
		}
		.dot {
			border: 3px solid $primaryColor;
		}
		.dot-draft {
			border: 3px solid #f2be1b;
			border-radius: 4px;
		}
	}
}
