.slack-integration {
	width: calc(100vw - 128px);
	height: calc(100vh - 128px);
	padding: 35px;
	background-color: #f9faff;
	border-radius: 8px;
}

#security {
	margin-top: 23px;
	padding: 0px 64px 0px 32px;
	.heading {
		display: flex;
		justify-content: space-between;
		padding: 0.6rem;
		background: $backgroundGrey;
		border-radius: 3px;
		.text {
			margin-top: 0.6rem;
		}
	}
	.body {
		padding-left: 15px;
		padding-right: 15px;
	}
}

#leaves {
	margin-top: 23px;
	padding: 0px 64px 0px 32px;
	.heading {
		display: flex;
		justify-content: space-between;
		padding: 0.6rem;
		background: $backgroundGrey;
		border-radius: 3px;
		.text {
			margin-top: 0.6rem;
		}
	}
	.body {
		padding-left: 15px;
		padding-right: 15px;
	}
}

#user-billing {
	margin-top: 23px;
	// padding: 0px 64px 0px 32px;
	.heading {
		display: flex;
		justify-content: space-between;
		padding: 0.6rem;
		background: $backgroundGrey;
		border-radius: 3px;
		.text {
			margin-top: 0.6rem;
		}
	}
	.body {
		padding-left: 15px;
		padding-right: 15px;
	}
}

#connected-apps {
	margin-top: 23px;
	padding: 0px 64px 0px 32px;
	.heading {
		display: flex;
		justify-content: start;
		padding: 0.6rem;
		background: $backgroundGrey;
		border-radius: 3px;
		.text {
			margin-top: 0.6rem;
		}
	}
	.body {
		padding-left: 15px;
		padding-right: 15px;
	}
}
