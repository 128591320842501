#billing{
    margin-top:23px;
    padding:0px 64px 0px 32px;
    .heading{
        display: flex;
        justify-content: start;
        padding: 0.6rem;
        background: $backgroundGrey;
        border-radius: 3px;
        .text{
            margin-top: 0.6rem;
        }
 
    }
    .body{
        padding-left: 15px;
        padding-right: 15px;
        .lightText{
            color: $lightText;
            font-size: 12px;
            line-height: 14px;
            text-align: start;
        }
        .addCardButton{
            background: #FB7A03;
            border-radius: 3px;
            color:$offWhite;
            font-size: 1.5rem;
            padding:0 1rem;
            
        }
        .removeCardButton{
            background:transparent;
            color:$primaryColor;
            border:none;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
        }
    }
}