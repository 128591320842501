.fc-theme-standard th {
	height: 42px !important;
	border-left: none !important;
	border-right: none !important;
}
.fc-popover-header {
	background: none !important;
	.fc-icon {
		color: #fb7a03 !important;
	}
}
.fc-scrollgrid-sync-inner {
	height: 42px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	flex-direction: column !important;

	a {
		color: #696f6c !important;
		font-family: Tiempos Text Test !important;
		font-style: normal !important;
		font-weight: 300 !important;
		font-size: 12px !important;
		line-height: 16px !important;
	}
	.fc-daygrid-day-events {
		position: unset !important;
	}
}
.fc-event-time {
	font-family: Calibre !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 16px !important;
	color: #333333 !important;
}

.fc-event-title {
	font-family: Calibre !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 12px !important;
	color: #333333 !important;
}

.fc-daygrid-day-top {
	a {
		font-family: Calibre !important;
		font-style: normal !important;
		font-weight: 300 !important;
		font-size: 24px !important;
		line-height: 31px !important;
		text-align: center !important;
		color: #b4b4b4 !important;
	}
}
.fc-button-primary {
	background-color: #fb7a03 !important;
	border-color: #fb7a03 !important ;
}

.fc-button {
	width: 32px !important;
	height: 43px !important;
	border-radius: 0 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

// .fc-next-button {
// 	// position: absolute !important;
// 	// right: 0 !important;
// 	// z-index: 999 !important;
// }

// .fc-prev-button {
// 	// position: absolute !important;
// 	// z-index: 999 !important;
// }

.fc .fc-toolbar.fc-header-toolbar {
	position: relative !important;
	margin-bottom: 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
	background-color: white !important;
}
.fc .fc-daygrid-day-frame {
	flex-wrap: wrap !important;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
	width: fit-content;
}

.fc .fc-popover {
	max-height: 16%;
	height: auto;
	overflow-y: auto;
	max-width: 20%;
	z-index: 9 !important;
}

.MuiFormControl-root {
	background-color: rgb(246, 245, 242) !important;
}

.MuiInput-underline:before {
	border-bottom: none !important;
}

.MuiInput-underline:after {
	border-bottom: none !important;
}

.MuiPickersDay-daySelected {
	background-color: #fb7a03 !important;
	color: white !important;
}

.MuiButton-textPrimary {
	color: #fb7a03 !important;
}

// .MuiPickersDay-current {
// 	// color: #fb7a03 !important;
// }

// .MuiSvgIcon-root {
// 	path {
// 		fill: #fb7a03 !important;
// 	}
// }
.create-task-modal {
	width: 100%;
	height: 100%;
	min-height: 10vh;
	position: relative;
}

.css-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label {
	font-family: 'Calibre' !important;
	font-weight: 400 !important;
	font-style: normal !important;
	font-size: 12px !important;
	line-height: 14px !important;
	margin: 0 !important;
}

.css-9l3uo3 {
	font-family: 'Calibre' !important;
	font-weight: 400 !important;
	font-style: normal !important;
	font-size: 12px !important;
	line-height: 14px !important;
	margin: 0 !important;
}

.MuiPickersClock-pin {
	background-color: #fb7a03 !important;
}

.MuiPickersClockPointer-pointer {
	background-color: #fb7a03 !important;
}

.MuiPickersClockPointer-noPoint {
	background-color: #fb7a03 !important;
}

.MuiPickersClockPointer-thumb {
	border: 14px solid #fb7a03 !important;
}

.MuiPickersToolbar-toolbar {
	background-color: #fb7a03 !important;
}

.today-date-button {
	box-sizing: border-box;

	display: flex;
	align-items: center;
	padding: 4px 10px;
	background-color: white;

	border: 1px solid $primaryColor;
	border-radius: 4px;
}
