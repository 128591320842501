.draft-workflow-success-modal {
	display: flex;
	// padding: 24px;
	flex-direction: column;
	gap: 16px;
	width: 686px;
	background-color: #eef2f3;
	align-items: center;
	justify-content: center;
	position: relative;

	.draft-workflow-success-header {
		display: flex;
		flex-direction: column;
		gap: 24px;
		background-color: #eef2f3;
		width: 100%;
		padding: 24px 24px 0 24px;
	}
	.draft-workflow-success-body {
		display: flex;
		gap: 12px;
		width: 100%;
		flex-direction: column;
		min-height: 140px;
		overflow: auto;
		background-color: #eef2f3;
		border-radius: 4px 4px 0px 0px;
		max-height: 160px;
		padding: 24px 24px 0 24px;
	}

	.draft-workflow-success-footer {
		// position: absolute;
		bottom: 0;
		height: 64px;
		background-color: #f7f9fa;
		border-radius: 0px 0px 4px 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		width: 100%;
	}
}
