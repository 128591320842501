@import '../style-assets/colors';

.ErrorFound {
	height: 100vh;
	width: 100vw;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: $formbg;

	.error-found-content-container {
		width: 362px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 32px;
	}

	.restricted-access-container {
        width: 584px;
		border-radius: 4px;
		background: var(--Background-Primary-Background, #fcfdfd);
		box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
		display: flex;
		padding: 24px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
	}
}
