.document-upload-container {
	width: 100%;
	height: 100%;
	border: 1px solid $borderLightGrey;
	border-radius: 4px;
	box-shadow: 0px 2px 10px -4px $boxShaddow;
	padding: 50px;
	max-height: 100%;
	overflow: auto;
	position: relative;

	.document-upload-message {
		display: flex;
		justify-content: center;
		width: 50%;
		max-height: 150px;
		margin: 16px 0;
		overflow: auto;
	}

	.uploaded-files-list {
		width: 100%;
		max-height: 200px;
		overflow: auto;
	}
	.drop-zone-container {
		flex-grow: 1;
		border: 2px dashed $primaryColor;
		border-radius: 8px;
		padding: '20px';
		cursor: pointer;
		background-color: $rowColor;
		margin-top: 24px;
	}
}
.document-upload-success {
	border: 1px solid $borderLightGrey;
	width: 600px;
	box-shadow: 0px 2px 10px -4px $boxShaddow;
	padding: 32px;
}
.failed-files-title {
	height: 38;
	background-color: $formbg;
	padding: 8px 16px;
	border-radius: 4px;
}
.failed-files-list {
	max-height: 400px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;

	.failed-list-item {
		height: 38;
		background-color: $rowColor;
		border: 1px solid $backgroundWhite;
		padding: 8px 16px;
		border-radius: 4px;
		word-wrap: break-word;
	}
}
