@import '../../style-assets/colors';

.IconsList {
	height: 120px;
	overflow-y: auto;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	.selected {
		border: 1px dotted #fb7a03;
	}
}
