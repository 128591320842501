@import '../../style-assets/colors';
@import '../../style-assets/misc';

.ModalRoot {
	//   position: fixed;
	//   width: 100vw;
	//   height: 100vh;
	//   top: 0;
	//   left: 0;
	//   right: 0;
	//   bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	//   z-index: 20;

	.Content {
		width: 30rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		z-index: 1;
		position: absolute;
		// top: 9.6rem;
		left: 50%;
		transform: translate(-50%);
		/* border-radius: ${borderRadius}; */
	}

	.Header {
		width: calc(100% - #{$spacing32});
		padding: $spacing4 $spacing16;
		background-color: $formbg;
		border-radius: $borderRadius $borderRadius 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 4rem;
		box-sizing: border-box;
	}

	.Body {
		width: calc(100% - #{$spacing32});
		// padding: $spacing16;
		display: flex;
		flex-direction: column;
		background-color: white;
		border-radius: 0 0 $borderRadius $borderRadius;
		background-color: $formbg;
	}

	.Mask {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;

		background: rgba(0, 0, 0, 0.75);
	}

	.CloseButton {
		width: $spacing24;
		height: $spacing24;
	}
}

.SaveFilterModal {
	background: $formbg;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px 24px 18px 24px;
	border-radius: 4px 4px 0px 0px;
}

.ConfirmationModal {
	background: $formbg;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	border-radius: 4px 4px 0px 0px;
}

.material-ui-modal {
	display: flex;
	justify-content: center;
	align-items: center;
}

.automate-input-modal {
	width: 600px;
	border-radius: 4px 0px 0px 0px;
	border: none;
	outline: none;

	.container {
		display: flex;
		flex-direction: column;
		text-align: left;
		padding: 24px;
		gap: 24px;
		border-radius: 4px 4px 0px 0px;
		background: $formbg;
	}

	.action-buttons {
		display: flex;
		justify-content: space-between;
		padding: 16px 24px 16px 24px;
		border-radius: 0px 0px 4px 4px;
		background-color: white;
	}
}
.new-modal {
	min-width: 350px;
	background-color: #eef2f3;
	border-radius: 4px;

	.new-modal-content {
		display: flex;
		flex-direction: column;
		background-color: $formbg;
		border-radius: 4px 4px 0px 0px;
		gap: 16px;
		padding: 16px;
	}

	.new-modal-footer {
		height: 64px;
		background-color: #f7f9fa;
		border-radius: 0px 0px 4px 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
	}
}
