@import '../../style-assets/colors';

.my-task-details-modal {
	width: 406px;
	max-height: 475px;

	display: flex;
	flex-direction: column;

	// border: 2px solid red;
	background-color: white;
	border-radius: 4px;

	.task-details-body,
	.task-details-footer {
		padding: 16px 24px;
	}

	.task-details-body {
		background-color: $formbg;
		display: flex;
		flex-direction: column;
		gap: 20px;

        .detail-body{
            display: flex;
            flex-direction: column;
            gap: 8px;
            // border: 2px solid red;
        }
	}

	.task-details-footer {
		display: flex;
		justify-content: end;
	}
}
