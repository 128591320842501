.billing-history-modal {
	width: 900px;
	display: flex;
	flex-direction: column;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
    overflow: hidden;
}

.table-body {
	display: flex;
	flex-direction: column;
	background-color: $formbg;
	padding: 6px;
	padding-bottom: 0px;
    box-sizing: border-box;
    overflow: hidden;
}

.table-body-child{
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	box-sizing: border-box;
}

.billing-history-actions{
    background-color: white;
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-radius: 0px 0px 4px 4px ;
}
