@import '../../style-assets/colors';
@import '../../style-assets/misc';

.ClientSectionHeader {
	/* 48px total (47px without border) */
	padding: 1.2rem 0;
	display: flex;
	/* align-items: center; */
	align-items: baseline;
	justify-content: space-between;
	color: $newDarkHeading;
	align-items: center;
	.with-border {
		border-bottom: 0.1rem solid $borderGrey;
	}
}
