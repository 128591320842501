@import '../../style-assets/colors';
@import '../../style-assets/misc';

#Element {
	display: flex;
	justify-content: center;
	width: fit-content;
	min-width: 100px;
	height: fit-content;
	border-radius: $borderRadius;
	padding: 10px 12px 6px 12px !important;
	// padding: 8px;
	background-color: $primaryColor;
	color: $offWhite;
	cursor: pointer;

	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 16px !important;
	line-height: 20px;
	// ${transition(['color', 'background-color'])};

	// &:hover:not([disabled]) {
	//   background-color: ${buttonHover};
	// }
	&.simple {
		background: none;
		color: $darkText;
	}
	&:focus {
		outline: 0.1rem dotted $primaryColor;
		outline-offset: 0.2rem;
	}

	&.light,
	&.secondary {
		color: $darkText;
		background-color: $LightColor;

		&.active,
		&:active {
			background-color: $buttonActiveSecondary;
		}
	}

	&.full-width {
		width: 100%;
	}

	&.curved {
		border-radius: 50px;
	}

	&.transparent {
		background-color: transparent;
		&:hover {
			background-color: transparent;
		}
	}

	&:disabled {
		opacity: 0.5;
	}

	// &.active,
	// &:active {
	//   background-color: ${buttonActive};
	// }

	&.square {
		border-radius: 0;
	}

	&.light-green {
		color: $primaryColor;

		&:hover:not([disabled]) {
			color: white;
		}
	}
	box-shadow: none;
	border: none;
	outline: none;
}

.full-button {
	display: flex;
	justify-content: center;
	width: fit-content;
	height: fit-content;
	border-radius: $borderRadius;
	padding: 0.2rem 1.8rem 0.2rem 1.8rem;
	font-family: 'Calibre';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	background-color: $primaryColor;
	color: $offWhite;
	cursor: pointer;
	// ${transition(['color', 'background-color'])};

	// &:hover:not([disabled]) {
	//   background-color: ${buttonHover};
	// }
	&.simple {
		background: none;
		color: $darkText;
	}
	&:focus {
		outline: 0.1rem dotted $primaryColor;
		outline-offset: 0.2rem;
	}

	&.light,
	&.secondary {
		color: $darkText;
		background-color: $secondaryButtonGrey;

		&:hover:not([disabled]) {
			background-color: $buttonHoverSecondary;
		}

		&.active,
		&:active {
			background-color: $buttonActiveSecondary;
		}
	}

	&.full-width {
		width: 100%;
	}

	&.transparent {
		background-color: transparent;
		&:hover {
			background-color: transparent;
		}
	}

	&:disabled {
		opacity: 0.5;
	}

	// &.active,
	// &:active {
	//   background-color: ${buttonActive};
	// }

	&.square {
		border-radius: 0;
	}

	&.light-green {
		color: $primaryColor;

		&:hover:not([disabled]) {
			color: white;
		}
	}
	box-shadow: none;
	border: none;
	outline: none;
}

@keyframes glowing {
	0% {
		background-color: #fb9435;
		box-shadow: 0 0 5px #fb9435;
	}
	50% {
		background-color: #fb871c;
		box-shadow: 0 0 20px #fb871c;
	}
	100% {
		background-color: $primaryColor;
		box-shadow: 0 0 5px $primaryColor;
	}
}

.full-button-glowing {
	animation: glowing 1300ms infinite;
}
