.action-side-bar-view {
	position: fixed;
	top: 0;
	right: -400px;
	width: 370px;
	height: 100vh;
	padding: 24px;
	box-shadow: 0px 7px 29px 0px #64646f33;
	background-color: #f7f9fa;
	transition: right 0.2s ease-in-out;
	visibility: hidden;

	&.show {
		right: 0;
		visibility: visible;
	}
	&.hide {
		right: -400px;
		visibility: hidden;
	}
}
