@import '../../style-assets/colors';
@import '../../style-assets/misc';
@import '../../style-assets/spacing';

.custom-label {
	font-family: 'Calibre';
	font-size: 0.6rem;
	color: $labelColor;
	text-transform: uppercase;
	margin-bottom: $spacing16b;
}

.ProfilePage {
	input,
	textarea {
		background-color: white;
		border: none;
		padding: 6px;
		// border-bottom: 1px solid #333;
	}
	label {
		font-family: 'Centra-No2';
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 14px;
		color: #696f6c;
		text-transform: uppercase;
		margin-bottom: $spacing16b;
		padding-bottom: 0.2em;
	}

	input,
	select,
	option,
	textarea {
		// font-size: 0.8rem;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		// border: 2px solid red
	}
}
.barText {
	input {
		font-family: 'Centra-No2';
		background-color: $backgroundGrey;
		border: none;
		border-bottom: 1px solid #333;
		font-size: 24px;
	}
}

.FileInput {
	background: none;
	border: none;
}

.FileInput::-webkit-file-upload-button {
	width: fit-content;
	border-radius: $borderRadius;
	border: none;
	outline: none;
	padding: 0.5rem 1.5rem 0.5rem;

	font-family: 'Calibre-Semibold';
	font-size: 0.9rem;
	line-height: 1rem;
	cursor: pointer;
	color: $darkText;
	background-color: white;

	&.active,
	&:active {
		background-color: $buttonActiveSecondary;
	}
}

.form-input {
	font-family: 'Calibre';
	background-color: $backgroundGrey;
	border: none;
	border-bottom: 1px solid #28383c;
}

.new-input-designs {
	display: flex !important;
	flex-direction: column !important;
	gap: 6px !important;

	label {
		margin: 0 !important;
		padding: 0 !important;
		color: $lightText;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
	}
	input,
	textarea,
	select,
	.dropdown-text {
		color: $black;
		border: none !important;
		margin: 0 !important;
		padding: 0 !important;
		color: $darkText !important;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
		padding: 4px 4px 0px 4px !important;
		&:focus {
			border-bottom: 1px solid $primaryColor !important;
		}
	}

	.approval-text {
		margin: 0 !important;
		padding: 0 !important;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
		padding: 4px 4px 0px 4px !important;
	}

	.MuiInput-underline:before {
		border: none !important;
	}
	.MuiInput-underline:after {
		border: none !important;
	}

	.MuiFormControl-root {
		border: none !important;
		height: 25px !important;
		padding: 0 !important;
		margin: 0 !important;
		display: flex;
		justify-content: center;
		width: 100%;
	}
}

.MuiInputBase-root {
	background-color: white;
}

.improved-input-designs {
	display: flex !important;
	flex-direction: column !important;
	gap: 6px !important;

	label {
		margin: 0 !important;
		padding: 0 !important;
		color: $lightText;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
	}
	.input-field-wrapper {
		display: flex;
		position: relative;
		width: 80%;

		.input-field-icon {
			position: absolute;
			top: 3px;
			right: 10px;
		}
	}

	.quill-input-style {
		color: $black;
		border: none !important;
		margin: 0 !important;
		padding: 0 !important;
		color: $darkText !important;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
		// padding: 8px 6px 4px 6px !important;
		border-radius: 4px;
		// background-color: white;

		&:focus {
			border-bottom: 1px solid $primaryColor !important;
		}
	}
	.input-style,
	input,
	textarea,
	select,
	.dropdown-text {
		color: $black;
		border: none !important;
		margin: 0 !important;
		padding: 0 !important;
		color: $darkText !important;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
		padding: 8px 6px 4px 6px !important;
		border-radius: 4px;
		background-color: white;
	}

	.input-style:focus,
	input:not([type='radio']):focus,
	textarea:focus,
	select:focus,
	.dropdown-text:focus {
		border-bottom: 1px solid $primaryColor !important;
	}

	.approval-text {
		margin: 0 !important;
		padding: 0 !important;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
		padding: 4px 4px 0px 4px !important;
	}

	.MuiInput-underline:before {
		border: none !important;
	}
	.MuiInput-underline:after {
		border: none !important;
	}

	.MuiFormControl-root {
		border: none !important;
		min-height: 25px !important;
		padding: 0 !important;
		margin: 0 !important;
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.MuiFormHelperText-root {
		background-color: $formbg;
	}
}
.styledKeyboardDatePicker .MuiInputBase-input {
	visibility: hidden;
	width: 0;
}
.MuiPickersMonth-monthSelected {
	color: $primaryColor !important;
}

.is-invalid {
	background-color: #f8d7da !important;
	border-color: #f5c6cb !important;
	color: #c31313 !important;
}
.phone-number-container {
	display: flex !important;
	flex-direction: column !important;
	gap: 6px !important;

	label {
		margin: 0 !important;
		padding: 0 !important;
		color: $lightText;
		font-family: 'CentraLight';
		font-weight: light;
		font-size: 14px !important;
		line-height: 18px;
		text-transform: initial !important;
	}
}

.phone-input {
	&:focus {
		border-bottom: 1px solid $primaryColor !important;
		box-shadow: none !important;
	}
}
.toggle-button {
	width: 29px;
	height: 16px;
	display: flex;
	align-items: center;
	background-color: #8a8a8a;
	border-radius: 25px;
	position: relative;
	cursor: pointer;

	.thumb {
		width: 12px;
		height: 12px;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		top: 2px;
		left: 1px;
		transition-duration: 0.3s;
	}

	&.active {
		background-color: #fb7a03;

		.thumb {
			transform: translateX(15px);
		}
	}
}

.input-password-icon {
	color: $primaryColor;
	background-color: $backgroundGrey;

	cursor: pointer;
	position: absolute;
	top: 50%;
	left: 96%;
	transform: translate(-50%, -50%);
}

.hidder-password-input {
	-webkit-text-security: disc;
}

.select-day-of-the-week-container {
	font-family: 'CentraLight';
	font-weight: light;
	text-transform: initial !important;

	font-size: 14px !important;
	line-height: 18px;

	margin: 0 !important;
	padding: 0 !important;
	padding: 7px 8px 4px 8px !important;

	color: $darkText !important;
	background-color: white;

	border-radius: 4px;
	border: none !important;
}

.day-of-the-week {
	height: 22px;
	width: 22px;

	display: flex;
	justify-content: center;

	padding: 3px 5px 0px 5px;
	border-radius: 50%;

	cursor: pointer;

	&.selected {
		background-color: $lightprimary;
	}
}

.day-of-the-week:nth-child(1) {
	color: $primaryColor;
}
