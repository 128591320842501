.reassign-tasks-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.expandable-client-tasks {
		width: 98%;
		height: auto;
		align-self: center;
		padding: 12px;
		box-shadow: 0px 0px 24px 0px #959da533;
		border-radius: 4px;

		.text-ellipsis {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;
		}
	}
}
// }
