.subscription-plan {
	.singleLane {
		display: flex;
		justify-content: space-between;
	}
	.m8 {
		margin-top: 8px;
	}
	.m16 {
		margin-top: 16px;
	}
	.button {
		width: 121px;
		height: 32px;
		margin-bottom: 24px;
		margin-top: 15px;
	}
}
