@import "../style-assets/colors";
@import "../style-assets/spacing";
@import "../style-assets/typography";

.CompanyProfile {
    display: flex;
    .error{
        color: #D8000C;
        background-color: #ffcbcb;
        border:1px solid red;
        border-radius: 3px;
    }
    .sidebarClientList{
        text-align: start;
        color: $offWhite;
        padding-left: 1rem;
        .CentraHeadline2 {
            color:$offWhite;
        }
        .clientsList{
            display: flex;
            justify-content: start;
            .avatar{
                width:1.5rem;
                height:1.5rem;
                background: $secondaryButtonGrey;
                border-radius:50% ;
                display: flex;
                justify-content: center;
                align-items: center;
                color:$black;
            }
            .text{
                font-size: 14px;
                align-self: center;
                line-height: 16px;
            }
        }
        
    }
    .input{
        margin-top:0.2rem;
        background: $backgroundGrey;
        border-radius: 3px 3px 0px 0px;
        border-color: $black;
        border-style: none none solid none;

        height:26px;

    }
    .exceptSidebar{  
        margin-left: auto;
        height: 100vh;
        width: calc(100vw - #{$sidebarWidth});
        box-sizing: border-box;
        overflow: auto;
        
    }
    .button82{
        background: $primaryColor;
        height: 32px;
        width: 82px;
        border-radius: 3px;
        border: none;
        color:$backgroundColor ;
    }
  }

  .stats-container{
    background-color: #EEF2F3;
    padding:18px;
    width: 100%;
    .header-bar{
        height: 50px;
        width: 100%;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 18px;
        background-color: #FCFDFD;
    }

    .graph-container{
        background-color: #F7F9FA;
        border-radius: 4px;
        margin-top: 12px;
        width: 100%;
    }
  }