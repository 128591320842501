.document-storage-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 100%;
	background-color: white;
	border-radius: 4px;

	.document-storage-header {
		height: 38px;
		padding: 8px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.folder-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;
		cursor: pointer;

		.folder-body {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 2px;
			height: 40px;
		}
		label {
			width: 100% !important;
			outline: none !important;
			margin-bottom: 1px !important;
		}
	}

	.file-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 40px;
		margin-bottom: 2px;
		cursor: pointer;
	}
}

.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}
