@import '../../style-assets/colors';

.workflow-browser-bar {
	width: 100%;
	background-color: $darkWhite;
	height: 45px;
	min-height: 2.5em;

	position: sticky;
	top: 0;

	// border: 1px solid #eaeaea;

	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;

	.workflow-browser-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		gap: 2.5em;
		align-items: center;
		// border: 2px solid red;

		margin: auto;
		padding: 0 1.8em 0 2em;

		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: $lightdarkText;
			margin: 0 !important;
			padding: 0 !important;
			height: 100%;
			cursor: pointer;
			// border: 2px solid red;
		}
		.active {
			border-bottom: 3px solid $primaryColor;
			padding-bottom: 2px;
			color: $black;
		}
		.display-none {
			display: none;
		}
	}
}

.workflow-browser {
	display: flex;
	height: 100vh;
	width: calc(100vw - #{$sidebarWidth});
	min-width: 1000px;
	box-sizing: border-box;
	overflow: auto;
	background-color: #eef2f3;
	padding: 4px 32px 36px 32px;

	.workflow-browser-header {
		width: 100%;
		height: 64px;
		padding: 16px;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fcfdfd;
	}

	.workflow-library-switcher {
		@extend .workflow-browser-header;
		height: 46px;
		margin-top: 35px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: sticky;
		top: -24px;
		z-index: 999;
	}

	.workflow-categories-header {
		height: 50px;
		padding: 12px 18px;
		@extend .workflow-browser-header;
	}
	.workflow-categories-body {
		height: auto;
		padding: 18px;
		gap: 18px;
		width: 100%;
		background-color: #eef2f3;
		border-radius: 4px;
		box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);

		.workflow-bar {
			width: 100%;
			background-color: #fcfdfd;
			box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
			border-radius: 4px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 12px 18px;
			height: 44px;
			margin: 16px 0px;
		}
		.workflow-details-box {
			background-color: #eef2f3;
			border-radius: 4px 4px 0px 0px;
			padding: 18px;
			width: 100%;
			height: fit-content;

			.workflow-details-header {
				height: 47px;
				width: 100%;
				padding: 12px 18px;
				border-radius: 4px;
				background-color: #fcfdfd;
				display: flex;
				margin-bottom: 16px;
			}
			.workflow-resources-container {
				background: #f7f9fa;
				border-radius: 4px;
				width: 100%;
				padding: 18px;
				max-height: 400px;
				overflow-y: auto;
				margin-bottom: 16px;
			}
		}
		.workflow-bottom-bar {
			background-color: #f7f9fa;
			border-radius: 0px 0px 4px 4px;
			width: 100%;
			height: 64px;
			padding: 16px;
			display: flex;
			justify-content: flex-end;
		}
	}
}

.background-effect {
	filter: drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2));
}

.dropdown-box {
	display: flex;
	width: fit-content;
	text-wrap: nowrap;
	min-height: auto;
	flex-direction: column;
	align-items: flex-start;
	padding: 6px 8px;
	border-radius: 4px;
	background: var(--light-l-100, #fff);
	box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
	z-index: 999;
	position: absolute;
	right: 0px;

	span {
		@extend .BodyTwoLight;
		width: 100%;
		text-align: left;
		cursor: pointer;
		padding: 6px 0px;
	}
}

.icon-header {
	width: 100%;
	height: 50px;
	padding: 12px 18px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	background-color: #fcfdfd;
}
