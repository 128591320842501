// Kindly press Ctrl+A and then press Ctrl+/ to use the code

// Primary Secondary and other colors
$lightprimary: #fed0a5;
$primaryColor: #fb7a03;
$redcolor: #f05544;
$greenColor: #18c58d;
$secondaryColor: #192235;
$secondaryLiteColor: #1a243a;
$secondaryLiteColor2: #232f47;
$secondaryLiteColor3: #283049;
$LightColor: #ededed;
$textColor: #d6e2f5;
$primaryDarkColor: #0b89c6;
$dangerColor: #b1464c;
$bgSidebar: #28383c;
$offWhite: #fffefb;
$black: #333333;
$darkText: #333;
$lightdarkText: #6d6f69;
$secondaryButtonGrey: #fef2e7;
$buttonHoverSecondary: #c6cfb9;
$buttonActiveSecondary: #abb69b;
$placeholderText: #b4b4b4;
$lightText: #696f6c;
$darkGreen: #28383c;
$borderGrey: #e9e7e1;
$backgroundGrey: #f6f5f2;
$backgroundWhite: #ffffff;
$backgroundyellow: #fff0b3;
$newDarkHeading: #333333;
$labelColor: #696f6c;
$darkWhite: #fcfbf7;
$tableHeaderBackground: #f6f5f2;
$skyBlue: #77c3de;
// Gradiant color
$errorColor: red;
// $gradiant: linear-gradient(158.81deg, #009ffd 0%, #2a2a72 100%);

// web background color
$formbg: #eef2f3;
$formActivebg: #fed0a5;
$backgroundColor: #f7f7f7;
$darkBackground: #e9e7e1;
$rowColor: #f7f9fa;
$lightgrey: #999999;
$boxShaddow: #cdddd840;
$borderLightGrey: #eaeaea;
