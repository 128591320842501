@import '../../style-assets/colors';

.shadow-border {
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	border-radius: 4px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	padding: 18px;
}

section {
	width: 100%;
	margin: auto;

	box-sizing: border-box;
	// border: 2px solid red;

	.progress-bar {
		border: 1px solid #eef2f3;
		box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
		border-radius: 4px;
		width: 100%;
		box-sizing: border-box;
		padding: 18px;
		background-color: #eef2f3;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		z-index: 1;

		.progress-bar-section {
			display: flex;
			flex: 1;
			flex-direction: column;
			gap: 12px;
			align-items: start;
		}
	}

	.workflow-color-picker {
		position: absolute;
		z-index: 2;
		width: 300px;
		top: 30px;
		right: 10px;
		background-color: #eef2f3;
		border-radius: 4px;
		box-shadow: 0px 8px 24px 0px #959da533;
		.color-picker-header {
			padding: 12px 0 0 12px;
		}
		.color-picker-body {
			padding: 12px 16px;
		}
		.color-picker-footer {
			background-color: white;
			height: 64;
			padding: 16px 24px;
		}
		.color-picker-error {
			padding: 0 16px 10px 16px;
		}
	}

	.Profile-Content {
		border: 1px solid #eef2f3;
		box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
		border-radius: 4px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.5em !important;
		// padding: 1em 0;
		box-sizing: border-box;
		padding: 18px;
		background-color: #eef2f3;
		// border: 2px solid green;
	}
	.background-shadow {
		filter: drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2));
	}

	.ProfileSection {
		// width: calc(100% - 1.2rem) !important;
		background-color: #f7f9fa;
		border-radius: 4px;
		padding: 18px;
		width: 100%;
		// border: 2px solid red;
	}
	.Filled-Table {
		background-color: $darkWhite;
		padding: 1.2rem;
	}
	.information-header {
		background: #fcfdfd;
		padding: 1em !important;
		height: 47px;
		border-radius: 4px;
	}
	.document-header {
		background: #fcfdfd;
		padding: 12px 18px;
		// height: ;
		border-radius: 4px;
	}
	.information-content {
		background-color: #f7f9fa;
		border-radius: 4px;
		// padding: 1em;
	}
	.workflow-notes {
		background: #f7f9fa;
		border-radius: 4px;
		width: 100%;
		padding: 18px;
		max-height: 400px;
		overflow-y: auto;
		margin-bottom: 16px;
	}
}
section {
	scroll-margin-top: 10px;
}
.footer {
	background-color: #f7f9fa;
	padding: 1.6em;
	box-sizing: border-box;
}
.draft-workflow-bar {
	background-color: #f7f9fa;
	width: 95%;
	border-radius: 4px;
	margin: 1rem 0px;
	padding: 1.2em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
}
