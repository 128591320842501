@import '../../style-assets/colors';

.primary-btn {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 16px !important;
	line-height: 20px;
	padding-top: 5px !important;
	letter-spacing: 0px;
	text-align: center;

	color: $offWhite;
	background: $primaryColor;

	padding-left: 12px;
	padding-right: 12px;

	border-radius: 3px;
	height: 32px;
	min-width: 100px;
	max-width: max-content;

	border: none;
	outline: none;

	&:focus {
		outline: 0.1rem dotted $primaryColor;
		outline-offset: 0.2rem;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.secondary-btn {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 16px !important;
	line-height: 20px;
	padding-top: 5px !important;
	letter-spacing: 0px;
	text-align: center;

	color: $darkText;
	background-color: $LightColor;

	border-radius: 3px;
	height: 32px;
	min-width: 100px;
	max-width: max-content;

	border: none;
	outline: none;

	&:focus {
		outline: 0.1rem dotted $primaryColor;
		outline-offset: 0.2rem;
	}
}

.secondary-btn-light {
	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 16px !important;
	line-height: 20px;
	padding-top: 5px !important;
	letter-spacing: 0px;
	text-align: center;

	color: $darkText;
	background-color: $LightColor;

	border-radius: 3px;
	height: 32px;
	min-width: 100px;
	max-width: max-content;

	border: none;
	outline: none;

	&:focus {
		outline: 0.1rem dotted $LightColor;
		outline-offset: 0.2rem;
	}
}
.curved {
	border-radius: 40px;
	padding: 0 12px;
	outline: none;
	border: none;
}

.rounded-button {
	padding: 5px 40px;
	border-radius: 40px;
	color: white;
	background: $primaryColor;
	border: none;
	outline: none;

	&.secondary {
		background-color: $LightColor;
		color: black;
	}

	&:disabled {
		opacity: 0.5;
	}
}
.rounded-btn {
	border-radius: 50px;
}

.rounded-button-1 {
	padding: 9px 12px 6px 12px;
	border-radius: 50px;

	min-width: 125px;
	height: 32px;
	white-space: nowrap;

	color: white;
	background: $primaryColor;

	outline: none;
	border: none;

	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 16px !important;
	line-height: 20px;

	&.secondary {
		background-color: $LightColor;
		color: black;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.rounded-button-2 {
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 10px 12px 6px 12px;
	border-radius: 50px;

	min-width: 125px;
	height: 32px;

	color: white;
	background: $primaryColor;

	outline: none;
	border: none;

	font-family: 'Centra-bold';
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 18px;

	&.secondary {
		background-color: $LightColor;
		color: black;
	}

	&:disabled {
		opacity: 0.5;
	}
}
