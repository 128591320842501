@import '../../style-assets/colors';

.workflow-category {
	width: fit-content;
	height: 16px;
	border-radius: 3px;
	padding: 0px 4px 0px 4px;

	background-color: $backgroundyellow;
}
