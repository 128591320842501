@import '../../style-assets/colors';

.my-task-card {
	display: flex;
	// flex-direction: column;
	// justify-content: space-around;

    width: 100%;
	// height: 46px;
	height: fit-content;
	overflow: hidden;

	padding: 8px;
	gap: 8px;

	overflow: hidden;
	box-sizing: border-box;

	cursor: pointer;

	background-color: white;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 13px 8px rgba(0, 0, 0, 0.05),
		0px 6px 6px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1),
		0px 0px 0px rgba(0, 0, 0, 0.1);
}
