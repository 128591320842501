.action-center {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	z-index: 100;
	right: 30px;
	bottom: 30px;

	.search-container {
		width: 45px;
		height: 45px;
		background-color: white;
		box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;

		.search-logo {
			width: 25px;
			height: 25px;
			transition: 0.3s;
			cursor: pointer;
			&:hover {
				width: 30px;
				height: 30px;
			}
		}
	}
	.action-logo {
		width: 50px;
		height: 50px;
		transition: 0.3s;
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		&:hover {
			width: 70px;
			height: 70px;
		}
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.3);
	}
	100% {
		-webkit-transform: scale(1);
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}

.pulse {
	-webkit-animation-name: pulse;
	animation-name: pulse;
	-webkit-animation-iteration-count: infinite;
}

.action-center-modal-container {
	position: absolute;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	z-index: 100;
	right: 30px;
	bottom: 120px;
}

.action-center-modal {
	width: 350px;
	min-height: 380px;
	height: auto;
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.25);
}

.status-modal-container {
	position: absolute;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	right: 30px;
	bottom: 120px;
}

.status-modal {
	width: 350px;
	min-height: 200px;
	height: auto;
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.25);
}

.rmdp-container .rmdp-week .rmdp-week-day {
	color: #696f6c !important;
}

.rmdp-arrow {
	border: solid $primaryColor !important;
	border-width: 0 2px 2px 0 !important;
}

.rmdp-day.rmdp-today span {
	background-color: $primaryColor !important;
}
.rmdp-arrow-container {
	&:hover {
		background-color: white !important;
		box-shadow: none !important;
	}
}
.rmdp-day span {
	&:hover {
		background-color: #fba003 !important;
		color: #ffffff !important;
	}
}
.rmdp-day.rmdp-selected span:not(.highlight) {
	background-color: #fba003 !important;
	box-shadow: none !important;
}

.notification-class {
	background-color: #ffffff;

	&:hover {
		background-color: #f6f5f2;
	}
}
