.meeting-my-details-modal {
	width: 80vw;
	min-width: 900px;
	max-width: 1000px;
	height: 85vh;
	max-height: 750px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
	background-color: $formbg;
	border-radius: 4px;

	.meeting-task-details-body {
		display: flex;
		height: 562px;
		flex-direction: column;
		gap: 12px;
		padding: 12px 24px 24px 24px;
	}
	.meeting-task-details-header {
		min-height: 140px;
		padding: 16px 24px;
		display: flex;
		gap: 8px;

		.header-details {
			width: 100%;
			height: fit-content;
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding-left: 8px;
		}
	}

	.meeting-task-details-footer {
		display: flex;
		width: 100%;
		height: 64px;
		justify-content: end;
		align-items: center;
		background-color: white;
		padding: 16px 24px;
	}
}

.task-tabs-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-radius: 4px;

	.task-tabs {
		display: flex;
		height: 32px;
		width: 100%;
		box-sizing: border-box;
		color: #fb7a03;
		gap: 12px;
		justify-content: start;
		align-items: center;
	}
	.task-tab {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 150px;
		box-sizing: border-box;
		padding: 10px;
		border-radius: 3px;
		cursor: pointer;
	}
	.active-tab {
		background-color: #fed0a5;
	}
	.task-tabs-details-container {
		border-radius: 4px;
		height: 422px;
		max-height: 422px;
	}
}
.meeting-task-agenda-container {
	background-color: #fcfdfd;
	padding: 16px;
	height: 242px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	border-radius: 4px;
	width: 100%;
}
.meeting-task-notes-container {
	background-color: #fcfdfd;
	padding: 16px;
	min-height: 220px;
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 12px;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	border-radius: 4px;
	width: 100%;
}
.meeting-guest-container {
	background-color: #fcfdfd;
	padding: 16px 24px;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-radius: 4px;
	width: 100%;
}
.meeting-comments-container {
	width: 100%;
	height: 162px;
	background-color: #fcfdfd;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	border-radius: 4px;

	.emoji-icon {
		position: absolute;
		left: 92%;
		top: 20%;
	}
	.emoji-picker {
		position: absolute;
		right: 50px;
		z-index: 100;
		bottom: 65px;
		box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
		border-radius: 4px;
	}
	.EmojiPickerReact {
		.epr-preview {
			display: none;
		}
	}
}
.ql-toolbar,
.ql-container {
	// border: none;
	background-color: white;
	border-radius: 4px;
}
.ql-toolbar.ql-snow {
	border: none !important;
}
.ql-container.ql-snow {
	border: none !important;
	// min-height: 65px;
	overflow: auto;
}
.ql-editor.ql-blank::before {
	font-style: normal !important;
	color: #b4b4b4 !important;
}

