@import '../../style-assets/colors';
@import '../../style-assets/spacing';
@import '../../style-assets/misc';

.TableHeader {
	display: flex;
	height: 34px;
	align-items: center;
	background-color: #ffffff;
	padding: 0 $spacing24;
	border-radius: 4px 4px 0 0;
	// border: 2px solid red;
}

.new-table-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px 8px 16px;
	background-color: white;
	border-radius: 4px 4px 0px 0px;
}

.tracking-table-header {
	position: sticky;
	top: 0;
	width: 100% !important;
	background-color: white;
	border-radius: 4px 4px 0px 0px;
	height: 38px !important;

	th {
		padding: 10px 16px;
	}
}

.tracking-table-body {
	overflow-y: auto;
	background-color: #ffffff;
	// border: 2px solid blue;

	tr {
		background: $rowColor;
	}
	td {
		padding: 10px 16px !important;
	}
}

.with-border {
	border-bottom: 0.1rem solid $borderGrey;
}

.flex {
	justify-content: space-around;
	// border: 2px solid blue;
}

.fixedHeader {
	position: sticky;
	top: 3.7rem;
	z-index: 1;
}
