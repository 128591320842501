@import './spacing';
@import './colors';
@import './typography';

@media only screen and (max-width: 767px) {
	.on-mobile-display {
		display: flex !important;
	}

	.on-laptop-display {
		display: none !important;
	}
}

@media only screen and (min-width: 768px) {
	.on-mobile-display {
		display: none !important;
	}

	.on-laptop-display {
		display: flex !important;
	}
}

// SideBar
$CardHeaderHeight: 3.7rem;
$sidebarWidth: 260px;
$navbarHeight: 40px;
$borderRadius: 0.2rem;

.fadeIn {
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	animation: 0.3s ease-out fadeIn;
}

// Fonts
$raleway: Raleway, sans-serif;
$workSans: 'Work Sans', sans-serif;
$fontSizeXsmall: 1.3rem;
$fontSizeSmall: 1.4rem;
$fontSizeMedium: 1.6rem;
$fontSizeLarge: 1.8rem;
$fontSizeXlarge: 2rem;
$fontSizeXxlarge: 3rem;

// Spacer
.VerticalSpacer {
	margin-bottom: $spacing16b;
}
.VerticalSpacer:last-child {
	margin-bottom: 0;
}

.opacity-50 {
	opacity: 0.5;
}
html {
	scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.rounded-1 {
	border-radius: 4px;
}
