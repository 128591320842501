@import '../../../styles/style-assets/colors';

.team-profile-main {
	background-color: $formbg;
}

.team-details-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	gap: 12px;
	box-sizing: border-box;
	height: max-content;
	background-color: white;
	padding: 24px 18px;
	border-radius: 4px;
}

.team-members-body {
	// border-radius: 4px;
	// border: 2px solid red;
}

.team-clients {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.team-client-select {
	background-color: #EEF2F3;
	padding: 4px 8px;
	height: 32px;
	border: none;
	border-radius: 4px;
	outline: none;
}

.team-client-workflows-container {
	padding: 18px;
	border-radius: 4px;
	background-color: $formbg;
	filter: drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2));
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.new-workflow-table-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	border-radius: 4px;
	padding: 8px 16px 4px 16px;
	box-sizing: border-box;
}
