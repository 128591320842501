@import '../../style-assets/colors';

.error-report-modal {
	width: 400px;
	height: auto;
	max-height: 60vh;

    // border: 2px solid red;
    background-color: $formbg;
    border-radius: 4px;
    box-sizing: border-box;

    .content{
        // border: 2px solid red;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    
        .form-title{
            width: 100%;
            // border: 2px solid red;
        }

        .input-container{
            width: 100%;
            // border: 2px solid red;

            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }

    .action-buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        background: white;
        
        padding: 12px 24px;

        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
