@import '../../style-assets/colors';

.time-report-preview {
	height: 100vh;
	width: calc(100vw - #{$sidebarWidth});
	// min-width: 900px;

	background-color: $formbg;
	padding: 0px 32px;
	overflow: auto;
}

.time-report-preview-content-wrapper {
	display: flex;
	flex-direction: column;

	min-width: 900px;
}

.date-range-bar {
	border-radius: 4px;
	padding: 14px 12px 10px 12px;
	background-color: $formbg;
}

.TimeReportsPreviewTable {
	width: 100%;
	text-align: left;

	thead {
		color: $lightText !important;
	}
	th,
	td {
		padding: 8px 16px;
		@extend .BodyTwoLight;
	}

	tr {
		border-top: 2px solid white;
	}

	.bold-td {
		padding: 11px 16px 5px 16px;
		@extend .BodyTwoBold;
	}
}
