.login-screen {
	min-width: 100vw;
	min-height: 100vh;
	background: #f2f2f2;
	.dfCenter {
		display: flex;
		justify-content: center;
	}
	.error {
		display: flex;
		justify-content: start;
		color: red;
		margin-top: 7px;
	}
	.tCenter {
		text-align: center;
	}
	.levvyLogo {
		width: 81.57px;
		height: 82.37px;
	}
	.LoginBox {
		background: #fffefb;
		padding: 32px 40px 32px 40px;
		box-shadow: 0px 2px 10px -4px #cdddd8;
		border-radius: 8px;
		width: 416px;
	}
	.LoginBox591 {
		background: #fffefb;
		padding: 32px 40px 32px 40px;
		box-shadow: 0px 2px 10px -4px #cdddd8;
		border-radius: 8px;
		width: 591px;
	}
	.signUpText {
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		color: #333333;
	}
	.formText {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		color: #696f6c;
	}
	button {
		background: #fb7a03;
		height: 32px;
		color: #ffffff;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		border: 0;
		border-radius: 3px;
	}
	.titleBold {
		font-size: 24px;
		font-weight: 700;
		line-height: 31px;
		color: #333333;
		text-align: center;
		margin-bottom: 30px;
	}
	label {
		margin-bottom: 9px;
		display: block;
		margin-top: 15px;
	}
	input {
		height: 26px;
		background: #f6f5f2;
		border-radius: 3px 3px 0px 0px;
		border: 0;
		border-bottom: 1.5px solid black;
		width: 100%;
	}
	input:focus {
		outline: none !important;
	}

	.m8 {
		margin-top: 8px;
	}
	.m16 {
		margin-top: 16px;
	}
	.m24 {
		margin-top: 24px;
	}
	.dGrid {
		display: grid;
	}

	@media screen and (max-width: 480px) {
		.LoginBox {
			padding: 32px 31px 32px 31px;
			width: 330px;
		}
		.levvyLogo {
			width: 50.51px;
			height: 51px;
		}
		.LoginBox591 {
			padding: 32px;

			width: 343px;
		}
	}
}
