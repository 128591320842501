@import '../../style-assets/colors';

.workflow-bar {
	height: 62px;
	width: 100%;

	background-color: #fcfdfd;
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 16px;

	border-radius: 3px 3px 0px 0px;
}

.workflow-category-bar {
	height: 40px;
	width: 100%;

	background-color: $darkWhite;
	display: flex;
	align-items: center;

	padding: 14px 24px 14px 24px;

	border-radius: 3px 3px 0px 0px;
}

